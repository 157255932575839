import React, { useState, useEffect } from "react";
import qs from 'query-string';
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import usePlans from "../../hooks/usePlans";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputMask from 'react-input-mask';
import api from "../../services/api";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import { nomeEmpresa, versionSystem } from "../../../package.json";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { i18n } from "../../translate/i18n";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import moment from "moment";

const useStyles = makeStyles(() => ({
	"@global": {
		body: {
		  margin: 0,
		  padding: 0,
		  boxSizing: "border-box",
		  fontFamily: "'Montserrat', sans-serif", 
		  backgroundColor: "#c9d6ff",
		  background: "linear-gradient(to right, #e2e2e2, #c9d6ff)",
		  display: "flex",
		  justifyContent: "center",
		  alignItems: "center",
		  height: "100vh",
		},  
	}, 
  
	container: {
		backgroundColor: "#fff",
		borderRadius: "30px",
		boxShadow: "0 5px 15px rgba(0, 0, 0, 0.35)",
		display: "flex",
		width: "700px", 
		height: "600px", 
		maxwidth: "400px",
    margin: "0 auto",
		overflow: "hidden",
    "@media (max-width: 600px)": {
      flexDirection: "column", 
      width: "90%", 
      maxWidth: "400px",
      height: "auto", 
      margin: "0 auto", 
    },
	  },
  innerContainer: {
    display: 'flex',
    width: '100%',
    maxWidth: '900px',
    height: '100%',
    borderRadius: '30px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.35)',
    backgroundColor: '#fff',
  },
  toggleContainer: {
    width: "50%",
    backgroundColor: "#25d366",
    background: "linear-gradient(to right, #128c7e, #25d366)",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px",
	  borderTopLeftRadius: '30px', 
	  borderBottomLeftRadius: '30px',
    "@media (max-width: 600px)": {
      display: "none", 
    },
  },
  togglePanel: {
    textAlign: "center",
  },
  togglePanelText: {
    fontSize: "16px",
    lineHeight: "24px",
    "@media (max-width: 600px)": {
      fontSize: "14px", 
    },
  },
  formContainer: {
    width: "50%", 
    padding: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "'Montserrat', sans-serif",
    "@media (max-width: 600px)": {
      width: "100%", 
      padding: "20px", 
    },
  },

  logo: {
    height: '60px',
    marginBottom: '20px',
    "@media (max-width: 600px)": {
      height: "50px", 
    },
  },
  button: {
    backgroundColor: '#09C184',
    color: '#fff',
    fontSize: '14px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 600,
    marginTop: '10px',
    width: '100%',
	  transition: 'none', 
	  width: "100%",
    maxwidth: "300px",
 	'&:hover': {
		backgroundColor: '#09C184', 
	},
	'&:active': {
		backgroundColor: '#09C184', 
	},
  "@media (max-width: 600px)": {
    fontSize: "12px", 
    padding: "10px 25px", 
  },
},
  input: {
	margin: '0 0',   
    padding: '5px 5px',
    fontSize: '14px',
    borderRadius: '6px',
    width: '100%',
    outline: 'none',
    maxwidth: '300px',
    transition: 'border-color 0.3s ease',
  },
  inputFocus: {
    borderColor: '#09C184',
  },

  link: {
    color: "#333",
    fontSize: "13px",
	  align: "center",
    textDecoration: "none",
    margin: "15px 0 10px",
    "&:hover": {
      color: "#09C184",
      textDecoration: "underline",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px", 
    },
  },
  headerText: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  subText: {
    fontSize: '16px',
  },
  
}));

const UserSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18n.t("form.nameTooShort"))
    .max(50, i18n.t("form.nameTooLong"))
    .required(i18n.t("form.required")),
  password: Yup.string().min(5, i18n.t("form.passwordTooShort")).max(50, i18n.t("form.passwordTooLong")),
  email: Yup.string().email(i18n.t("form.invalidEmail")).required(i18n.t("form.required")),
});

const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const [allowregister, setallowregister] = useState('enabled');
  const [trial, settrial] = useState('3');
  let companyId = null;

  useEffect(() => {
    fetchallowregister();
    fetchtrial();
  }, []);

  const fetchtrial = async () => {
    try {
      const responsevvv = await api.get("/settings/trial");
      const allowtrialX = responsevvv.data.value;
      settrial(allowtrialX);
    } catch (error) {
      console.error('Error retrieving trial', error);
    }
  };

  const fetchallowregister = async () => {
    try {
      const responsevv = await api.get("/settings/allowregister");
      const allowregisterX = responsevv.data.value;
      setallowregister(allowregisterX);
    } catch (error) {
      console.error('Error retrieving allowregister', error);
    }
  };

  if (allowregister === "disabled") {
    history.push("/login");
  }

  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  const initialState = { name: "", email: "", phone: "", password: "", planId: "disabled", };

  const [user] = useState(initialState);
  const dueDate = moment().add(trial, "day").format();

  const handleSignUp = async values => {
    Object.assign(values, { recurrence: "MENSAL" });
    Object.assign(values, { dueDate: dueDate });
    Object.assign(values, { status: "t" });
    Object.assign(values, { campaignsEnabled: true });
    try {
      await openApi.post("/companies/cadastro", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login");
    } catch (err) {
      const errorMsg = err.response?.data?.error;
      toastError(i18n.exists(`backendErrors.${errorMsg}`) ? err : i18n.t("signup.toasts.fail"));
    }
  };

  const [plans, setPlans] = useState([]);
  const { register: listPlans } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const list = await listPlans();
      setPlans(list);
    }
    fetchData();
  }, []);

  const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/signup.png`;
  const randomValue = Math.random(); 
  const logoWithRandom = `${logo}?r=${randomValue}`;

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.toggleContainer}>
          <div className={classes.togglePanel}>
            <Typography className={classes.headerText}>Bem-vindo!</Typography>
            <Typography className={classes.subText}>Crie sua conta para acessar todos os recursos da plataforma.</Typography>
          </div>
        </div>
        <div className={classes.formContainer}>
          <img className={classes.logo} src={logoWithRandom} alt="Logo" />
          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={UserSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSignUp(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>
                <Grid container spacing={1} style={{ marginTop: '1px' }}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      fullWidth
                      id="name"
                      label={i18n.t("signup.form.name")}
                      required
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      id="email"
                      label={i18n.t("signup.form.email")}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      autoComplete="email"
                      required
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={12}>
							<Field
									as={InputMask}
									mask="(99) 99999-9999"
									variant="outlined"
									fullWidth
									id="phone"
									name="phone"
									error={touched.phone && Boolean(errors.phone)}
									helperText={touched.phone && errors.phone}
									autoComplete="phone"
									required
								>
									{({ field }) => (
										<TextField
											{...field}
											variant="outlined"
											fullWidth
											label="DDD988888888"
											className={classes.input} 
										/>
									)}
								</Field>
							</Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      type="password"
                      name="password"
                      variant="outlined"
                      fullWidth
                      id="password"
                      label={i18n.t("signup.form.password")}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      required
                      className={classes.input}
                    />
                        <Grid item xs={12}>
									<InputLabel htmlFor="plan-selection">Plano</InputLabel>
									<Field
										as={Select}
										variant="outlined"
										fullWidth
										id="plan-selection"
										label="Plano"
										name="planId"
										required
										style={{ maxWidth: "275px" }}
									>
                                        <MenuItem value="disabled" disabled>
                                        	<em>Selecione seu plano de assinatura</em>
										</MenuItem>
										{plans.map((plan, key) => (
											<MenuItem key={key} value={plan.id}>
										        {plan.name} - {plan.connections} WhatsApps - {plan.users} Usuários - R$ {plan.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
											</MenuItem>
										))}
									</Field>
								</Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.button}
                      disabled={isSubmitting}
                    >
                     CADASTRAR
                    </Button>
                  </Grid>
                </Grid>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link component={RouterLink} to="/login" variant="body2" className={classes.link}>
                      {i18n.t("Já possui uma conta? Entre")}
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignUp;




// import React, { useState, useEffect } from "react";
// import qs from 'query-string'

// import * as Yup from "yup";
// import { useHistory } from "react-router-dom";
// import { Link as RouterLink } from "react-router-dom";
// import { toast } from "react-toastify";
// import { Formik, Form, Field } from "formik";
// import usePlans from "../../hooks/usePlans";
// import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import TextField from "@material-ui/core/TextField";
// import Link from "@material-ui/core/Link";
// import Grid from "@material-ui/core/Grid";
// import Box from "@material-ui/core/Box";
// import InputMask from 'react-input-mask';
// import api from "../../services/api";
// import {
// 	FormControl,
// 	InputLabel,
// 	MenuItem,
// 	Select,
// } from "@material-ui/core";
// import { nomeEmpresa, versionSystem } from "../../../package.json"
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
// import { i18n } from "../../translate/i18n";

// import { openApi } from "../../services/api";
// import toastError from "../../errors/toastError";
// import moment from "moment";
// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyright © "}
// 			<Link color="inherit" href="#">
// 				{ nomeEmpresa } - v { versionSystem }
// 			</Link>{" "}
// 		   {new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

// const useStyles = makeStyles(theme => ({
// 	paper: {
// 		marginTop: theme.spacing(8),
// 		display: "flex",
// 		flexDirection: "column",
// 		alignItems: "center",
// 	},
// 	avatar: {
// 		margin: theme.spacing(1),
// 		backgroundColor: theme.palette.secondary.main,
// 	},
// 	form: {
// 		width: "100%",
// 		marginTop: theme.spacing(3),
// 	},
// 	submit: {
// 		margin: theme.spacing(3, 0, 2),
// 	},
// }));

// const UserSchema = Yup.object().shape({
// 	name: Yup.string()
// 		.min(2, i18n.t("form.nameTooShort"))
// 		.max(50, i18n.t("form.nameTooLong"))
// 		.required(i18n.t("form.required")),
// 	password: Yup.string().min(5, i18n.t("form.passwordTooShort")).max(50, i18n.t("form.passwordTooLong")),
// 	email: Yup.string().email(i18n.t("form.invalidEmail")).required(i18n.t("form.required")),
// });

// const SignUp = () => {
// 	const classes = useStyles();
// 	const history = useHistory();
// 	const [allowregister, setallowregister] = useState('enabled');
//     const [trial, settrial] = useState('3');
// 	let companyId = null

// 	useEffect(() => {
//         fetchallowregister();
//         fetchtrial();
//     }, []);


//     const fetchtrial = async () => {
  
 
//     try {
//         const responsevvv = await api.get("/settings/trial");
//         const allowtrialX = responsevvv.data.value;
//         //console.log(allowregisterX);
//         settrial(allowtrialX);
//         } catch (error) {
//             console.error('Error retrieving trial', error);
//         }
//     };


//     const fetchallowregister = async () => {
  
 
//     try {
//         const responsevv = await api.get("/settings/allowregister");
//         const allowregisterX = responsevv.data.value;
//         //console.log(allowregisterX);
//         setallowregister(allowregisterX);
//         } catch (error) {
//             console.error('Error retrieving allowregister', error);
//         }
//     };

//     if(allowregister === "disabled"){
//     	history.push("/login");    
//     }

// 	const params = qs.parse(window.location.search)
// 	if (params.companyId !== undefined) {
// 		companyId = params.companyId
// 	}

// 	const initialState = { name: "", email: "", phone: "", password: "", planId: "disabled", };

// 	const [user] = useState(initialState);
// 	const dueDate = moment().add(trial, "day").format();
// 	const handleSignUp = async values => {
// 		Object.assign(values, { recurrence: "MENSAL" });
// 		Object.assign(values, { dueDate: dueDate });
// 		Object.assign(values, { status: "t" });
// 		Object.assign(values, { campaignsEnabled: true });
// 		try {
// 			await openApi.post("/companies/cadastro", values);
// 			toast.success(i18n.t("signup.toasts.success"));
// 			history.push("/login");
// 		} catch (err) {
// 			console.log(err);
			
// 			const errorMsg = err.response?.data?.error;
// 			toastError(i18n.exists(`backendErrors.${errorMsg}`) ? err : i18n.t("signup.toasts.fail"));
// 		}
// 	};

// 	const [plans, setPlans] = useState([]);
// 	const { register: listPlans } = usePlans();

// 	useEffect(() => {
// 		async function fetchData() {
// 			const list = await listPlans();
// 			setPlans(list);
// 		}
// 		fetchData();
// 	}, []);

// 	const logo = `${process.env.REACT_APP_BACKEND_URL}/public/logotipos/signup.png`;
//     const randomValue = Math.random(); // Generate a random number
  
//     const logoWithRandom = `${logo}?r=${randomValue}`;


// 	return (
// 		<Container component="main" maxWidth="xs">
// 			<CssBaseline />
// 			<div className={classes.paper}>
// 				<div>
// 				<img style={{ margin: "0 auto", width: "100%" }} src={logoWithRandom} alt={`${process.env.REACT_APP_NAME_SYSTEM}`} />
// 				</div>
// 				{/*<Typography component="h1" variant="h5">
// 					{i18n.t("signup.title")}
// 				</Typography>*/}
// 				{/* <form className={classes.form} noValidate onSubmit={handleSignUp}> */}
// 				<Formik
// 					initialValues={user}
// 					enableReinitialize={true}
// 					validationSchema={UserSchema}
// 					onSubmit={(values, actions) => {
// 						setTimeout(() => {
// 							handleSignUp(values);
// 							actions.setSubmitting(false);
// 						}, 400);
// 					}}
// 				>
// 					{({ touched, errors, isSubmitting }) => (
// 						<Form className={classes.form}>
// 							<Grid container spacing={2}>
// 								<Grid item xs={12}>
// 									<Field
// 										as={TextField}
// 										autoComplete="name"
// 										name="name"
// 										error={touched.name && Boolean(errors.name)}
// 										helperText={touched.name && errors.name}
// 										variant="outlined"
// 										fullWidth
// 										id="name"
// 										label={i18n.t("signup.form.name")}
// 										required
// 									/>
// 								</Grid>

// 								<Grid item xs={12}>
// 									<Field
// 										as={TextField}
// 										variant="outlined"
// 										fullWidth
// 										id="email"
// 										label={i18n.t("signup.form.email")}
// 										name="email"
// 										error={touched.email && Boolean(errors.email)}
// 										helperText={touched.email && errors.email}
// 										autoComplete="email"
// 										required
// 									/>
// 								</Grid>
								
// 							<Grid item xs={12}>
// 								<Field
// 									as={InputMask}
// 									mask="(99) 99999-9999"
// 									variant="outlined"
// 									fullWidth
// 									id="phone"
// 									name="phone"
// 									error={touched.phone && Boolean(errors.phone)}
// 									helperText={touched.phone && errors.phone}
// 									autoComplete="phone"
// 									required
// 								>
// 									{({ field }) => (
// 										<TextField
// 											{...field}
// 											variant="outlined"
// 											fullWidth
// 											label="DDD988888888"
// 											inputProps={{ maxLength: 11 }} // Definindo o limite de caracteres
// 										/>
// 									)}
// 								</Field>
// 							</Grid>
// 								<Grid item xs={12}>
// 									<Field
// 										as={TextField}
// 										variant="outlined"
// 										fullWidth
// 										name="password"
// 										error={touched.password && Boolean(errors.password)}
// 										helperText={touched.password && errors.password}
// 										label={i18n.t("signup.form.password")}
// 										type="password"
// 										id="password"
// 										autoComplete="current-password"
// 										required
// 									/>
// 								</Grid>
// 								<Grid item xs={12}>
// 									<InputLabel htmlFor="plan-selection">Plano</InputLabel>
// 									<Field
// 										as={Select}
// 										variant="outlined"
// 										fullWidth
// 										id="plan-selection"
// 										label="Plano"
// 										name="planId"
// 										required
// 									>
//                                         <MenuItem value="disabled" disabled>
//                                         	<em>Selecione seu plano de assinatura</em>
// 										</MenuItem>
// 										{plans.map((plan, key) => (
// 											<MenuItem key={key} value={plan.id}>
// 										        {plan.name} - {plan.connections} WhatsApps - {plan.users} Usuários - R$ {plan.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
// 											</MenuItem>
// 										))}
// 									</Field>
// 								</Grid>
// 							</Grid>
// 							<Button
// 								type="submit"
// 								fullWidth
// 								variant="contained"
// 								color="primary"
// 								className={classes.submit}
// 							>
// 								{i18n.t("signup.buttons.submit")}
// 							</Button>
// 							<Grid container justify="flex-end">
// 								<Grid item>
// 									<Link
// 										href="#"
// 										variant="body2"
// 										component={RouterLink}
// 										to="/login"
// 									>
// 										{i18n.t("signup.buttons.login")}
// 									</Link>
// 								</Grid>
// 							</Grid>
// 						</Form>
// 					)}
// 				</Formik>
// 			</div>
// 			<Box mt={5}>{/* <Copyright /> */}</Box>
// 		</Container>
// 	);
// };

// export default SignUp;
